import React from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image"
import { isEmpty } from "lodash-es";
import { format } from "date-fns";

import Layout from "../components/layout/Layout";
import { ButtonSymbolHover } from "../components/Button";
import ShareButtons from "../components/ShareButtons";
import ArticleItem from "../components/ArticleItem";
import { PageLinkList } from "../components/PageLinkList";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";
import { getDefinedTermPopUpItem } from "../helpers";

export default ({ data, location, pageContext }) => {
    const {
        title,
        subtitle,
        author,
        content,
        color,
        date,
        createdAt,
        updatedAt,
        liftImage,
        metaTags,
        relatedArticles,
        pageLinkLists,
        pageLinkListHeader
    } = data.contentfulArticle;

    const seoData = {
        updatedAt: updatedAt,
        createdAt: createdAt,
        author: author.name,
        image: liftImage.file.url,
        metaTags: metaTags,
    }

    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

    const getDatestamp = () => {
        const dateAsDate = new Date(date);
        return format(dateAsDate, 'dd.MM.yyyy');
    };

    const isLocaleDe = () => pageContext.locale === 'de';

    const getTranslation = (key) => {
        if (key === 'contactUs') {
            return isLocaleDe() ? 'Kontaktieren Sie uns' : 'contact us';
        }
        if (key === 'relatedArticlesTitle') {
            return isLocaleDe() ? 'Verwandte Artikel' : 'related articles';
        }
        return key;
    };

    return (
        <Layout
            title={title}
            transparentNavigation={true}
            isDark={true}
            isArticle={true}
            seoData={seoData}
            location={location}
            language={pageContext.locale}
        >
            <main id="content" style={{ marginTop: "-80px" }}>
                <section className={`hero is-fullheight ${color !== null ? `is-${color}-tertiary-bg` : "is-orange-tertiary-bg"}`}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <h1 className={`font-bold size-66 line-height-70 ${color !== null ? `is-${color}` : "is-orange"}`}>{title}</h1>
                                    <p className="size-22 line-height-32 is-black padding-y-20">{subtitle}</p>
                                    <div className="columns is-vcentered padding-y-10">
                                        <div className="column is-2 is-flex" style={{ width: 'auto' }}>
                                            <img src={getSrc(author.portrait)} alt={author.name} width="64" height="64" className="round margin-x-10 black-and-white" />
                                        </div>
                                        <div className="column">
                                            {author.linkedInPage ? (
                                                <a href={author.linkedInPage} target="_blank" rel="noopener noreferrer">
                                                    <p className={`size-16 ${color !== null ? `is-${color}` : "is-orange"}`}>
                                                        {getDatestamp()}
                                                    </p>
                                                    <p className="size-16 is-black">{author.name}</p>
                                                    <p className="size-14 is-grey-1">{author.title}</p>
                                                </a>
                                            ) : (
                                                <React.Fragment>
                                                    <p className={`size-16 ${color !== null ? `is-${color}` : "is-orange"}`}>
                                                        {getDatestamp()}
                                                    </p>
                                                    <p className="size-16 is-black">{author.name}</p>
                                                    <p className="size-14 is-grey-1">{author.title}</p>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`section ${color !== null ? `is-${color}-article-gradient` : "is-orange-article-gradient"}`}>
                    <div className="container">
                        <div className="columns">
                            <div className={`column is-four-fifths is-offset-1 ${color !== null ? `is-${color}` : "is-orange"}`}>
                                <ContentHTMLWithHoverPopUps
                                    contentHTML={content.childMarkdownRemark.html}
                                    popUpItems={definedTermsPopUpItems}
                                    className={`article-content content is-${color}`}
                                />
                                <ButtonSymbolHover
                                    color={color}
                                    text={getTranslation('contactUs')}
                                    type="outlined"
                                    to="/ask-us-anything"
                                />
                                <ShareButtons
                                    url={location.href}
                                    title={`Read about ${title}`}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {pageLinkLists !== null && (
                    <section className={`section is-medium is-${color}-bg`}>
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <PageLinkList lists={pageLinkLists} header={pageLinkListHeader} />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {!isEmpty(relatedArticles) && (
                    <section className="hero is-medium">
                        <div className="hero-body">
                            <div className="container">
                                <h2 className="ingress size-40">
                                    {getTranslation('relatedArticlesTitle')}
                                </h2>
                                <div className="columns is-mobile is-multiline">
                                    {relatedArticles.map((post, i) => (
                                        <div className="column is-4-desktop is-12-mobile" key={i}>
                                            <ArticleItem
                                                item={post}
                                                color={post.color || ["green"]}
                                                locale={pageContext.locale}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </main>
        </Layout>
    )
}

export const query = graphql`
query ($contentful_id: String!, $locale: String, $contentful_content_vocabulary_item_slugs: [String]) {
    contentfulArticle(
        contentful_id: {eq: $contentful_id}
        node_locale: {eq: $locale}
    ) {
        metaTags
        date
        createdAt
        updatedAt
        title
        subtitle
        color
        pageLinkListHeader
        pageLinkLists {
            linkList {
              ... on ContentfulWebinarV2 {
                __typename
                id
                title
                webinarUrl
                webinarFreeText {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                webinarImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulServicesSubpage {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulLanding {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCustomerType {
                __typename
                id
                name
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
              ... on ContentfulCustomer {
                __typename
                id
                name
                description
                slug
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCoSellPartner {
                __typename
                id
                name
                slug
                slogan
              }
              ... on ContentfulArticle {
                __typename
                id
                title
                articleUrl
                subtitle
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
              ... on ContentfulAcademy {
                __typename
                id
                title
              }
            }
            title
          }
        liftImage {
            file {
                url
            }
        }
        author {
            title
            name
            linkedInPage
            portrait {
                gatsbyImageData(width: 64, quality: 100)
            }
        }
        content {
            childMarkdownRemark {
                html
            }
        }
        relatedArticles {
            title
            articleUrl
            color
            subtitle
            date(formatString: "DD.MM.YYYY")
            category
            liftImage {
                gatsbyImageData(width: 1820, quality: 100)
                title
            }
        }
    }
    allContentfulDefinedTerm(
        filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: $locale}}
        ) {
        nodes {
            slug
            title
            description {
                childMarkdownRemark {
                    html
                    excerpt
                }
            }
        }
    }
}
`
